import styled, { css } from 'styled-components'
import { Link as DomLinkC } from 'react-router-dom'

export const commonsStyles = css<{ $disabled?: boolean }>`
  ${({ theme }) => theme.textStyles.text};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.pureBlack};
  display: inline-flex;
  text-decoration: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const DomLink = styled(DomLinkC)<{ $disabled?: boolean }>`
  ${commonsStyles}
`

export const Link = styled.a<{ $disabled?: boolean }>`
  ${commonsStyles}
`

export const Button = styled.button<{ $disabled?: boolean }>`
  ${commonsStyles}
  background-color: transparent;
`

export const Span = styled.span<{ $disabled?: boolean }>``
