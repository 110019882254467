import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react'

import * as SC from './styled'

export type FormFieldInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name?: string
  className?: string
  error?: string
  hasError?: boolean
  help?: string
  hideError?: boolean
  errors?: { message?: string }[]
  label?: string
  required?: boolean
  leftAdornment?: React.ReactElement
  rightAdornment?: React.ReactElement
  InputComponent?: FC<any>
  inputProps?: any
  hasFloatingLabel?: boolean
  loading?: boolean
}

const FormFieldInputRenderer: ForwardRefRenderFunction<any, FormFieldInputProps> = (props, ref) => {
  const {
    className,
    name,
    id,
    error,
    hasError,
    placeholder,
    onChange,
    label,
    required,
    help,
    leftAdornment,
    rightAdornment,
    InputComponent,
    inputProps,
    value,
    hasFloatingLabel,
    loading,
    ...extraProps
  } = props
  const localId = useId()
  const [isFocused, setFocused] = useState(false)
  const isActive = isFocused || !!value
  const hasLabel = !!label

  const CustomInput: FC<any> = InputComponent ? InputComponent : SC.Input

  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        {!hasFloatingLabel && (
          <SC.Label required={required} $active={isActive} $isFloating={hasFloatingLabel}>
            <SC.Loader display={loading}>{label}</SC.Loader>
          </SC.Label>
        )}
        <SC.InputContainer
          $isError={!!error || hasError}
          $isActive={isActive}
          $isDisabled={extraProps.disabled}
          $hasLabel={hasLabel}
        >
          {hasFloatingLabel && (
            <SC.Label required={required} $active={isActive} $isFloating={hasFloatingLabel}>
              <SC.Loader display={loading}>{label}</SC.Loader>
            </SC.Label>
          )}
          <SC.InputWrapper>
            {leftAdornment}
            <CustomInput
              ref={ref}
              {...inputProps}
              {...extraProps}
              id={id ?? localId}
              name={name}
              type={extraProps.type ?? 'text'}
              placeholder={!hasLabel || !hasFloatingLabel || isFocused ? placeholder : undefined}
              value={value}
              $isError={!!error || hasError}
              $isActive={isActive}
              $hasLabel={hasLabel}
              $hasFloatingLabel={hasFloatingLabel}
              $isDisabled={extraProps.disabled}
              onChange={onChange}
              onFocus={(e: any) => {
                setFocused(true)
                extraProps?.onFocus?.(e)
              }}
              onBlur={(e: any) => {
                extraProps?.onBlur?.(e)
                setFocused(false)
              }}
            />
            {rightAdornment}
          </SC.InputWrapper>
        </SC.InputContainer>
      </SC.Wrapper>
      {!error && help && <SC.HelpMessage>{help}</SC.HelpMessage>}
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.Container>
  )
}

const FormInput: FC<FormFieldInputProps & { ref?: React.Ref<any> }> = forwardRef(
  FormFieldInputRenderer
)

export default FormInput
