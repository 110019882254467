import styled from 'styled-components'

import Link from '../Link'
import Section from '../Section'
import ActionButton from '../ActionButton'

export const Container = styled.footer``

export const Top = styled.div`
  text-align: center;
  padding: 2.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.pureBlack};
`

export const Button = styled(ActionButton)`
  text-transform: uppercase;
`

export const Block = styled(Section)`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.pureBlack};
`

export const Bottom = styled.div`
  text-align: center;
  padding: 1.4rem 0;
`

export const LegalTerms = styled(Link)`
  ${(props) => props.theme.textStyles.textSmall}
  text-transform: uppercase;
`

export const BottomText = styled(Link)`
  ${(props) => props.theme.textStyles.textSmall}
`
