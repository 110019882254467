import React from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LegalsTemplate, { LegalsTemplateProps } from '../../templates/Legals'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { router, routesPath } from '../../router'

const LegalsPage: React.FC<RouteProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const props: LegalsTemplateProps = {
    header: <Header logoLink={{ href: router(routesPath.home) }} />,
    footer: (
      <Footer
        bottomText={t('footer.bottomText')}
        legalTerms={{ text: t('footer.legalTerms') }}
        redirectButton={{
          text: t('home.redirectButton.text'),
          href: 'https://www.balenciaga.com/',
          target: '_blank',
        }}
      />
    ),
    onClose: () => navigate(-1),
    title: t('legals.title'),
  }

  return (
    <LegalsTemplate {...props}>
      <p>Date: May 2023</p>
      <p>Welcome to spring24.balenciaga.com (”Website”).</p>
      <br />
      <br />
      <h2>Legal Notice</h2>
      <br />
      <p>
        The Website is owned and operated by <strong>BALENCIAGA</strong>, a simplified joint-stock
        company incorporated under French law with a share capital of 23.271.525 €, registered with
        the Paris Trade and Corporate Register under unique identification number 775 668 122 and
        with registered office at 16-18 RUE VANEAU 75007 Paris France (“Balenciaga” or “we”, “us”,
        “our”).
      </p>
      <br />
      <p>
        The Website’s publishing director is Mr. Cédric Charbit. The Website is hosted by Amazon Web
        Services, Inc. P.O. Box 81226, Seattle, WA 98108-1226 (tel: 001 206 266 7010).
      </p>
      <br />
      <br />
      <h2>Purpose of the Website</h2>
      <br />
      <p>
        The Website is made available by Balenciaga for its users to download and use a customizable
        JPEG document, as a computer or mobile phone screensaver.
      </p>
      <br />
      <h2>
        Please read carefully the following terms of use of the Website (the “ToU”) before using the
        Website
      </h2>
      <br />
      <br />
      <h2>1. Definitions and Acceptance of these ToU</h2>
      <br />
      <p>These ToU applies to your access to, and use of, the Website, and the Content. </p>
      <br />
      <p>
        The term &quot;Content&quot; means all information, documentation and materials included on
        the Website, including the design, layout, look, appearance, graphics, organization of the
        Website Content, photographs, images, illustrations, text, fonts, videos, music, sound,
        audio clips, logos, trademarks, service marks, any other marks, trade names, business names,
        domain names and URLs, as well as software and any other material downloaded, extracted or
        filtered from the Website.
      </p>
      <br />
      <p>
        When accessing the Website for the first time, you will be asked to agree to these ToU. If
        you do not agree to be bound by these ToU, do not use the Website. We reserve the right to
        amend or update all or part of these ToU from time to time at our discretion. Please note
        that, when visiting the Website for the first time after changes to the ToU have been
        uploaded, you will be asked to agree to the updated ToU. If you do not agree with the
        revised ToU, do not use the Website.
      </p>
      <br />
      <br />
      <h2>2. Accessing the Website and the Content</h2>
      <br />
      <p>
        Ensure that you have all necessary equipment, hardware, software, operating systems, network
        cards, Internet browser and other elements allowing you to access and use the Website and
        the Content. You assume all responsibility for any access to the Website and the Content.
      </p>
      <br />
      <p>General access to the Website is open to any user of the Website.</p>
      <br />
      <br />
      <h2>3. Use of the Website and the Content</h2>
      <br />
      <p>
        You agree that in using the Website, you will not:
        <ul>
          <li>
            use the Website in any way that interrupts, causes or may cause damage to the Website,
            impairs the Website’s availability or accessibility or renders the Website less
            efficient;
          </li>
          <li>
            circumvent or attempt to circumvent the Website’s security measures and tamper with the
            technology that forms part of the Website;
          </li>
          <li>
            obtain or attempt to obtain any information, materials or documents not purposely made
            available to all through the Website through any means (such as the press kit);
          </li>
          <li>breach any applicable law;</li>
          <li>
            use the Website for any unlawful purpose or in a way which infringes the rights of any
            third parties;
          </li>
          <li>
            If you are a private individual, you are permitted to consult the Content from the
            Website exclusively for your private use.{' '}
          </li>
        </ul>
        Any use which is not expressly permitted by these ToU is prohibited.
      </p>
      <br />
      <p>
        We reserve the right to deny, restrict, suspend, or terminate your access to the Website and
        to the Content (including the press kit) at any time, without notice and without liability
        to you, if your use of the Website or the Content is in breach of any of your obligations
        under these ToU, or any other provisions of these ToU, without prejudice to any other
        remedies available to us under applicable law and under these ToU.{' '}
      </p>
      <br />
      <br />
      <h2>4. Intellectual Property Rights</h2>
      <br />
      <p>
        The Website and the Content are either owned or licensed by us, and are protected by
        applicable copyright, trademark, patent or other intellectual property laws around the
        world. We reserve all such rights.
        <br />
        Unauthorized use of the Website or the Content contained on it may violate applicable
        intellectual property laws or other laws.
      </p>
      <br />
      <br />
      <h2>5. Links</h2>
      <br />
      <p>
        <strong>Links to Third Party Sites</strong>. The Website may include links to third-parties’
        websites (“Linked Sites”). We have no control over such Linked Sites and will not be
        responsible or liable for the availability of the Linked Sites or for their content,
        products or services. These links are provided solely for your convenience and should not be
        interpreted as an endorsement by us of such Linked Sites or their contents, products or
        services, their privacy and security practices or the manner in which they conduct their
        operations.
        <br />
        <strong>Links to our Website</strong>. You are not permitted to frame the Website or its
        Content on any other website, or to link to our Website, any page of it and/or to the
        Content, without our prior written consent.
      </p>
      <br />
      <br />
      <h2>6. No Warranties</h2>
      <br />
      <p>
        The Website and the Content are provided free of charge on an “as-is” and “as available”
        basis and we exclude all warranties or guarantees in connection with this Website and the
        Content, to the extent permitted by law. By way of example, we do not guarantee that the
        Website will always be available, without interruption or errors in functioning, or that it
        will be safe from malicious programs such as viruses, bugs, malware or similar, or that it
        is suitable for any particular purposes.
      </p>
      <br />
      <p>
        We work to ensure that the information made available through the Website is accurate and up
        to date. However, we cannot guarantee the accuracy of such information (including the
        Content) or that such information is free from errors or omissions and we make no warranty,
        and shall have no liability, in respect of the same.
      </p>
      <br />
      <br />
      <h2>7. Limitation of Liability</h2>
      <br />
      <p>
        To the fullest extent permitted by applicable law, Balenciaga and its licensors shall not be
        liable for any damages of any kind (whether direct or indirect) arising out of or in
        connection with (i) the access or use of, or inability to access or use, the Website or the
        Content, nor (ii) any third-party claims, even if Balenciaga has been advised of the
        possibility of such damages. Nothing in these ToU limits or excludes our liability for any
        liability which cannot be limited or excluded by applicable law. Some jurisdictions do not
        allow certain exclusions or limitations of warranty or liability, and consequently some of
        the above exclusions and limitations may not apply
      </p>
      <br />
      <br />
      <h2>8. Governing Law and Jurisdiction</h2>
      <br />
      <p>
        These ToU and any matter relating to your access to, or use of, the Website shall be
        governed by and will be interpreted in accordance with the laws of France (without reference
        to its conflict of law provisions).
        <br />
        You and we agree to submit any dispute arising out of or relating to, these ToU including
        the validity, interpretation, breach or termination thereof, to the exclusive jurisdiction
        of the courts within the jurisdiction of the Paris Court of Appeal.
      </p>
      <br />
      <br />
      <h2>9. Contact us</h2>
      <br />
      <p>
        If you have any questions about these ToU or the Website, you can contact us in one of the
        following ways:
        <ul>
          <li>By regular mail to BALENCIAGA – 16-18 RUE VANEAU 75007 Paris France</li>
          <li>By telephone at + 33 1 56 52 17 17</li>
        </ul>
      </p>
      <br />
      <br />
      <h2>Privacy Statement</h2>
      <br />
      <p>
        When using the Website, Balenciaga may process your personal data to provide you with the
        services and administer, optimize, improve or secure this Website. This processing is
        carried out in accordance with the provisions of the Terms &{' '}
        <a href="https://www.balenciaga.com/fr-fr/legal/politique-de-confidentialite-client/privacy-policy.html">
          Conditions and Balenciaga Privacy Policy
        </a>
        . Privacy laws may grant you certain rights such as the right to access, delete, modify and
        rectify your data, or to restrict or object to processing, as well as the right to data
        portability. You can also lodge a complaint with your competent regulator. For further
        information regarding our privacy practices and your rights, please contact us at{' '}
        <a href="mailto:privacy@balenciaga.com">privacy@balenciaga.com</a>.
      </p>
      <br />
      <br />
    </LegalsTemplate>
  )
}

export default LegalsPage
