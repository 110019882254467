import React, { useCallback, useMemo, useRef, useState } from 'react'
import { generatePath, RouteProps } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'

import HomeTemplate, { HomeTemplateProps } from '../../templates/Home'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { router, routesPath } from '../../router'
import Link from '../../components/Link'
import { api } from '../../configuration'
import useDebouncedCallback from '../../utils/useDebouncedCallback'

const HomePage: React.FC<RouteProps> = () => {
  const { t } = useTranslation()
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [letters, setLetters] = useState<string>('')
  const [lettersValide, setLettersValide] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)
  const formattedText = useMemo(() => {
    return lettersValide
      .split('')
      .reduce((acc, val, i) => {
        return i === 0 ? acc + val : acc + '.' + val + (i === lettersValide.length - 1 ? '.' : '')
      }, '')
      .toUpperCase()
  }, [lettersValide])

  const isDisabled = useMemo(() => {
    return lettersValide.length === 0 || !!error || processing
  }, [error, lettersValide, processing])

  const verifyRequest = useDebouncedCallback((letters: string) => {
    if (letters.length > 0) {
      fetch(
        `${api.url}/api/verify?` +
          new URLSearchParams({
            q: letters,
          })
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.success === true) {
            setError(t('home.input.error.forbiddenCombination'))
            setLettersValide('')
          } else {
            setError('')
            setLettersValide(letters)
          }

          setProcessing(false)
        })
    } else {
      setLettersValide('')
      setProcessing(false)
      setError('')
    }
  }, 500)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = new RegExp('[^a-zA-Z]')
      const value = e.target.value.toLowerCase().replaceAll(' ', '')

      if (regex.test(value)) return
      if (value.length > 3) return

      setProcessing(true)
      setLetters(value)
      verifyRequest(value)
    },
    [verifyRequest, setLetters, setProcessing]
  )

  const templateProps: HomeTemplateProps = useMemo(
    () => ({
      header: <Header logoLink={{ href: router(routesPath.home) }} />,
      footer: (
        <Footer
          bottomText={t('footer.bottomText')}
          legalTerms={{ text: t('footer.legalTerms'), href: router(generatePath('legals')) }}
          redirectButton={{
            text: t('home.redirectButton.text'),
            href: 'https://www.balenciaga.com/',
            target: '_blank',
          }}
        />
      ),
      canvas: {
        canvasInnerRef: canvasRef,
        letters: error ? '' : formattedText,
      },
      input: {
        loading: processing,
        label: t('home.input.label'),
        required: true,
        help: t('home.input.help', { number: letters.length }),
        value: letters,
        placeholder: t('home.input.placeholder'),
        error,
        onChange: handleChange,
      },
      downloadButton: {
        text: t('home.downloadButton.text'),
        disabled: isDisabled,
        onClick: () => {
          if (canvasRef.current && !isDisabled) {
            canvasRef.current.toBlob((blob) => {
              if (blob) {
                saveAs(blob, 'image.png')
              }
            })
          }
        },
      },
      extraWording: (
        <Trans
          t={t}
          i18nKey={'home.termsOfDownload'}
          components={{
            link: (
              <Link text={t('home.termsOfDownloadLink')} href={router(generatePath('legals'))} />
            ),
          }}
        />
      ),
      information: (
        <>
          <p>{t('home.info.english')}</p>
        </>
      ),
    }),
    [t, letters, isDisabled, formattedText, error, handleChange, processing]
  )

  return <HomeTemplate {...templateProps} />
}

export default HomePage
