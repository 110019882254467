import styled, { css } from 'styled-components'

import type { FormLabelProps } from './index'

export const Label = styled.label<{ $error?: boolean } & Omit<FormLabelProps, 'error'>>`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  ${(props) => props.theme.textStyles.text};

  &[required]:after {
    content: ' *required';
    color: ${(props) => props.theme.colors.grey};
  }

  ${(props) =>
    props.$error &&
    css`
      color: ${(props) => props.theme.colors.red};
    `}
`
