import React, { FC } from 'react'

import DefaultLayout, { DefaultLayoutProps } from '../../layouts/DefaultLayout'

import * as SC from './styled'

export type LegalsTemplateProps = DefaultLayoutProps & {
  className?: string
  title?: string
  children?: React.ReactNode
  onClose?: () => void
}

const LegalsTemplate: FC<LegalsTemplateProps> = ({ title, children, onClose, ...layout }) => {
  return (
    <DefaultLayout {...layout}>
      <SC.Close onClick={onClose}>
        <SC.CloseIcon width={25} />
      </SC.Close>
      <SC.Section>
        <SC.Title>{title}</SC.Title>
        {children && <SC.Children>{children}</SC.Children>}
      </SC.Section>
    </DefaultLayout>
  )
}

export default LegalsTemplate
