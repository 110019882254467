import styled from 'styled-components'

import Section from '../../components/Section'
import Canvas from '../../components/Canvas'
import ActionButton from '../../components/ActionButton'
import FormInput from '../../components/form/fields/FormFieldInput'

export const SectionStyled = styled(Section)`
  text-align: center;
`

export const Field = styled(FormInput)`
  text-align: left;
`
export const Wording = styled.p`
  text-align: left;
  margin-top: 1rem;
  ${({ theme }) => theme.textStyles.textSmall}
  letter-spacing: 0.01em;

  & a {
    text-decoration: underline;
    ${({ theme }) => theme.textStyles.textSmall}
    letter-spacing: 0.01em;
  }
`

export const CanvasStyled = styled(Canvas)``
export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const Button = styled(ActionButton)`
  text-transform: uppercase;
  width: 100%;
  margin-top: 1.2rem;

  span {
    letter-spacing: 0.03em;
    ${({ theme }) => theme.textStyles.textSmall}
  }
`
