import { RouteType } from '../types/routes'
import HomePage from '../pages/Home'
import LegalsPage from '../pages/Legals'

import { routesPath } from './index'

const publicRoutes: RouteType = {
  path: '',
  component: null,
  children: [
    {
      path: routesPath.home,
      name: 'Home',
      component: HomePage,
    },
    {
      path: routesPath.legals,
      name: 'Legals',
      component: LegalsPage,
    },
  ],
}

export const defaultLayoutRoutes = [publicRoutes]
