import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

import type { ActionButtonProps } from '.'

export const ActionButton = styled(Link)<{
  disabled?: boolean
  $variant?: ActionButtonProps['variant']
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.7rem 1.6rem;
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  ${(props) => {
    switch (props.$variant) {
      case 'primary':
        return css`
          border: 1px solid ${(props) => props.theme.colors.pureBlack};
          color: ${(props) => props.theme.colors.pureWhite};
          background-color: ${(props) => props.theme.colors.pureBlack};
        `
      case 'secondary':
        return css`
          border: 1px solid ${(props) => props.theme.colors.pureBlack};
          color: ${(props) => props.theme.colors.pureBlack};
          background-color: ${(props) => props.theme.colors.pureWhite};
        `
    }
  }}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
      border-color: transparent;
      color: ${theme.colors.textDisabled};
    `}
`

export const Label = styled.span`
  ${({ theme }) => theme.textStyles.text}
  display: flex;
  align-items: center;
  min-height: 2.4rem;
`

export const ActionIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
`
