const colors = {
  pureBlack: '#000',
  pureWhite: '#ffffff',
  grey: '#767676',
  textDisabled: '#AAAAAC',
  red: '#FE576B',
  error: '#FE576B',
}

export default colors
