import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.BBCondBold}
    font-size: 3rem;
    text-transform: uppercase;
    line-height: initial;
  `,
  titleH2: `
    ${fonts.BBCondBold}
    font-size: 1.8rem;
    line-height: initial;
    text-transform: uppercase;
  `,
  text: `
    ${fonts.BBRegular}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  textSmall: `
    ${fonts.BBRegular}
    font-size: 1.2rem;
    line-height: normal;
  `,
  textTiny: `
    ${fonts.BBRegular}
    font-size: 1rem;
    line-height: normal;
  `,
}

export default textStyles
