import styled from 'styled-components'

import Icon, { Icons } from '../../components/Icon'

export const Title = styled.h1`
  ${({ theme }) => theme.textStyles.titleH1};
  font-size: 1.4rem;
  margin-bottom: 3rem;
`

export const Section = styled.section`
  padding: 0 1.2rem;
  max-width: calc(366px + 2.4rem);
  width: 100%;
  margin: auto;
  margin-top: 7.5rem;
`

export const Close = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 30%;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    right: 2rem;
  }
`
export const CloseIcon = styled(Icon).attrs({ icon: Icons.close })``

export const Children = styled.div`
  h2 {
    ${({ theme }) => theme.textStyles.text};
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  a {
    color: ${({ theme }) => theme.colors.pureBlack};
  }
`
