import React, { FC } from 'react'

import DefaultLayout, { DefaultLayoutProps } from '../../layouts/DefaultLayout'
import { CanvasProps } from '../../components/Canvas'
import { ActionButtonProps } from '../../components/ActionButton'
import { FormFieldInputProps } from '../../components/form/fields/FormFieldInput'

import * as SC from './styled'

export type HomeTemplateProps = DefaultLayoutProps & {
  className?: string
  canvas: CanvasProps
  input: FormFieldInputProps
  information: React.ReactNode
  downloadButton: ActionButtonProps
  extraWording?: React.ReactNode
}

const HomeTemplate: FC<HomeTemplateProps> = ({
  canvas,
  information,
  downloadButton,
  input,
  extraWording,
  ...layout
}) => {
  return (
    <DefaultLayout {...layout}>
      <SC.SectionStyled>
        <SC.CanvasStyled {...canvas} />
        <SC.Field {...input} />
        <SC.Button {...downloadButton} />
        {extraWording && <SC.Wording>{extraWording}</SC.Wording>}
      </SC.SectionStyled>
      <SC.SectionStyled removeBorder>
        <SC.Information>{information}</SC.Information>
      </SC.SectionStyled>
    </DefaultLayout>
  )
}

export default HomeTemplate
