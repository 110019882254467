import React, { FC } from 'react'
import cx from 'classnames'

import Logo from '../Logo'
import Link, { LinkProps } from '../Link'

import * as SC from './styled'

export type HeaderProps = {
  className?: string
  logoLink?: LinkProps
}

const Header: FC<HeaderProps> = ({ className, logoLink }) => {
  return (
    <SC.Container className={cx(className)}>
      <Link {...logoLink}>
        <Logo />
      </Link>
    </SC.Container>
  )
}

export default Header
