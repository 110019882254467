import React, { FC } from 'react'

import * as SC from './styled'

export type SpinnerProps = {
  className?: string
  display?: boolean
  children?: React.ReactNode
}
const Spinner: FC<SpinnerProps> = ({ children, display }) => (
  <>
    {display && <SC.Spinner>{children}</SC.Spinner>}
    {!display && <>{children}</>}
  </>
)

export default Spinner
