import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  legalTerms: LinkProps
  bottomText: string
  redirectButton?: ActionButtonProps
}

const Footer: FC<FooterProps> = ({ className, legalTerms, bottomText, redirectButton }) => {
  return (
    <SC.Container className={cx(className)}>
      {redirectButton && (
        <SC.Block>
          <SC.Button {...redirectButton} variant={'secondary'} />
        </SC.Block>
      )}
      <SC.Top>
        <SC.LegalTerms {...legalTerms} />
      </SC.Top>
      <SC.Bottom>
        <SC.BottomText>{bottomText}</SC.BottomText>
      </SC.Bottom>
    </SC.Container>
  )
}

export default Footer
