import { PayloadAction } from '@reduxjs/toolkit'
import { ResultOf } from '@graphql-typed-document-node/core'
import { put, takeEvery } from 'redux-saga/effects'
import { OperationVariables } from '@apollo/client/core/types'
import { TypedDocumentNode } from '@apollo/client'

import type ApiSagas from '../redux/api/sagas'

import {
  ApiResponse,
  GraphqlErrors,
  MutationService,
  QueryService,
  transformErrors,
} from './GraphqlHelpers'

export type ServiceValues<Data = any, Params = any> = {
  pending: boolean
  success: boolean
  complete: boolean
  errors: GraphqlErrors | null
  data: Data | null
  params: Params | null
}

export type ServiceRequestAction<T = undefined> = PayloadAction<T>
export type ServiceSuccessAction<Data> = PayloadAction<Data | undefined>
export type ServiceErrorAction = PayloadAction<any | undefined>

export const getServiceState = <Data>(data?: Data): ServiceValues<Data, any> => ({
  pending: false,
  success: false,
  complete: false,
  errors: null,
  data: data ?? null,
  params: null,
})

export const getServiceReducers = <Id extends string, Data, Params>(id: Id) => {
  const requestActionCreator = (state: any, action: ServiceRequestAction<Params>) => {
    const params = action.payload
    state[id] = {
      pending: true,
      success: false,
      complete: false,
      errors: null,
      data: state?.[id]?.data,
      params,
    }
  }

  const successActionCreator = (state: any, action: ServiceSuccessAction<Data>) => {
    const data = action.payload
    state[id] = {
      ...state[id],
      pending: false,
      success: true,
      complete: true,
      errors: null,
      data: data ?? null,
    }
  }

  const errorActionCreator = (state: any, action: ServiceErrorAction) => {
    const errors = transformErrors(action.payload)
    state[id] = {
      ...state[id],
      pending: false,
      success: false,
      complete: true,
      data: null,
      errors,
    }
  }

  const resetActionCreator = (state: any) => {
    state[id] = getServiceState()
  }

  return {
    [`${id}Request`]: requestActionCreator,
    [`${id}Success`]: successActionCreator,
    [`${id}Error`]: errorActionCreator,
    [`${id}Reset`]: resetActionCreator,
  } as Record<`${typeof id}Request`, typeof requestActionCreator> &
    Record<`${typeof id}Success`, typeof successActionCreator> &
    Record<`${typeof id}Error`, typeof errorActionCreator> &
    Record<`${typeof id}Reset`, typeof resetActionCreator>
}

export const getCustomService = <Id extends string, Data = null, Params = undefined>(
  id: Id,
  data?: Data | null
) => ({
  state: getServiceState(data),
  reducers: getServiceReducers<Id, Data, Params>(id),
})

export const getApiService = <
  Id extends string,
  Service extends
    | QueryService<TVariables, TData, Transformer>
    | MutationService<TVariables, TData, Transformer>,
  TVariables extends OperationVariables,
  Transformer extends (response: ResultOf<TypedDocumentNode<TData, TVariables>>) => any,
  TData = any
>(
  id: Id,
  service:
    | QueryService<TVariables, TData, Transformer>
    | MutationService<TVariables, TData, Transformer>,
  data?: ApiResponse<Service>['data'] | null
) => {
  const reducers = getServiceReducers<Id, ApiResponse<Service>['data'], TVariables>(id)

  const getSagaEffect = (call: typeof ApiSagas.call, sliceName: string) =>
    takeEvery(`${sliceName}/${id}Request`, function* (action: ServiceRequestAction) {
      const response = yield* call(service, action.payload)

      if (response?.errors) {
        yield put({ type: `${sliceName}/${id}Error`, payload: response!.errors })
        return
      }

      if (response?.data) {
        yield put({ type: `${sliceName}/${id}Success`, payload: response!.data })
      }
    })

  return {
    state: getServiceState(data),
    reducers,
    getSagaEffect,
  }
}
