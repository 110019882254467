import React, { FC, memo } from 'react'

import * as icons from './icons'
import * as SC from './styled'
import { IconProps } from './types'

export * from './types'

const Icon: FC<IconProps> = ({ className, icon, color, width, height, preserveAspectRatio }) => {
  const iconProps: any = icons[icon]
  const { viewBox, id } = iconProps

  return (
    <SC.Svg
      className={className}
      $color={color}
      viewBox={viewBox}
      {...(color && { color })}
      {...(width && { width })}
      {...(height && { height })}
      {...(preserveAspectRatio && { preserveAspectRatio })}
    >
      <use xlinkHref={`#${id}`} href={`#${id}`} />
    </SC.Svg>
  )
}
export default memo(Icon)
