import { generatePath } from 'react-router-dom'
import { Params } from 'react-router'

export const router = <S extends string>(path: S, params?: Params<S>) => {
  return generatePath(path, params as any)
}

export const routesPath = {
  home: '/',
  legals: '/legals',
}
