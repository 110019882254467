import React, { FC } from 'react'

import * as SC from './styled'

export type SectionProps = {
  className?: string
  children?: React.ReactNode
  removeBorder?: boolean
}

const Section: FC<SectionProps> = ({ className, removeBorder, children }) => {
  return (
    <SC.Container className={className} $removeBorder={removeBorder}>
      <SC.Content>{children}</SC.Content>
    </SC.Container>
  )
}

export default Section
