import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import * as SC from './styled'

const exportRatio = 0.5

export type CanvasProps = {
  className?: string
  letters?: string
  canvasInnerRef?: React.RefObject<HTMLCanvasElement | null>
}

const Canvas: FC<CanvasProps> = ({ className, letters, canvasInnerRef }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const keyRef = useRef<HTMLImageElement | null>(null)
  const goldRef = useRef<HTMLImageElement | null>(null)

  const [context, setContext] = useState<CanvasRenderingContext2D | null>()

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d')
    setContext(context)
  }, [])

  const draw = useCallback(() => {
    if (context && canvasRef?.current && keyRef?.current && goldRef?.current) {
      const gold = goldRef.current
      const key = keyRef.current
      const canvas = canvasRef.current

      canvas.width = key.width
      canvas.height = key.height
      context.clearRect(0, 0, canvas.width, canvas.height)

      context.font = `${122 * exportRatio}px BB-CondBold`
      context.fillStyle = 'white'
      context.textAlign = 'center'
      context.fillText(letters ?? '', (638 + 740 / 2) * exportRatio, (3468 + 160) * exportRatio)

      context.globalCompositeOperation = 'source-in' // = draw next instruction inside canvas pixels

      context.drawImage(gold, 0, 0)

      context.globalCompositeOperation = 'destination-atop' // = draw next instruction behind canvas pixels

      context.drawImage(key, 0, 0)
    }
  }, [context, letters])

  useEffect(() => {
    draw()
  }, [draw, letters])

  //
  // Preload
  //
  useEffect(() => {
    const gold = new Image(2019 * exportRatio, 4096 * exportRatio)
    gold.onload = () => {
      goldRef.current = gold
      draw()
    }
    gold.src = '/images/gold.png'

    const key = new Image(2019 * exportRatio, 4096 * exportRatio)
    key.onload = () => {
      keyRef.current = key
      draw()
    }
    key.src = '/images/key.png'
  }, [draw])

  return (
    <SC.Canvas
      ref={(ref) => {
        if (canvasInnerRef) {
          // @ts-ignore
          canvasInnerRef.current = ref
        }

        canvasRef.current = ref
      }}
      className={className}
    />
  )
}

export default Canvas
