import styled, { css } from 'styled-components'

export const Container = styled.div<{ $removeBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.pureBlack};
  padding: 6rem 0;

  ${({ $removeBorder }) =>
    $removeBorder &&
    css`
      border: none;
    `}
`

export const Content = styled.div`
  max-width: calc(464px + 12rem);
  width: 100%;
  padding: 0 6rem;
`
